.campaigns__table {
  border-radius: 26px 0 0 26px;
  padding: 25px 0 25px 25px;
}

.campaigns__table td,
.campaigns__table th {
  vertical-align: top;
  color: var(--text-color);
  padding: 14px 25px 14px 0;
  margin-bottom: 14px;
}

.campaigns__table td span.order__title,
.campaigns__table td span.order__client-name {
  display: block;
  max-width: 510px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.campaigns__table td span.order__client-name {
  max-width: 420px;
}

.campaigns__table th {
  color: #b1a1a8;
}

/* .campaigns__table .tr-overflow-auto,
.campaigns__table table {} */

.campaigns__table th {
  padding: 0 25px 14px 0;
}

.campaigns__table .order__title {
  margin-right: 25px;
}

.campaigns__table a {
  cursor: pointer;
  color: var(--blue);
}

.campaigns__table .status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 65%);
  box-shadow: 0px 3px 6px #0000000D;
  border-radius: 8px;
}

.campaigns__table .status>div {
  width: 12px;
  height: 12px;
  background-color: #FF632F;
  border-radius: 999px;
}

.campaigns__table .status>div.status--active {
  background-color: #78D99D;
}

.campaigns__table .order {
  display: flex;
  flex-direction: column;
}

.campaigns__table .order .order__infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
}

.campaigns__table .order .order__infos:hover {
  cursor: pointer;
  color: var(--blue);
}

.campaigns__table .order .order__infos:hover svg {
  stroke: var(--blue);
}

.campaigns__table .device {
  display: flex;
  align-items: center;
}

.campaigns__table .device svg {
  flex: 1;
  margin-right: 10px;
}

.campaigns__table .device svg:last-child {
  margin-right: 0;
}

.campaigns__table .line-items {
  display: none;
  background: rgb(255, 255, 255, 65%);
  box-shadow: 0px 10px 20px #1C296E0D;
  border-radius: 18px;
}

.campaigns__table .order.order--line-items__active .line-items {
  display: block;
  margin-top: 15px;
  padding: 20px 24px;
}

.campaigns__table .order.order--line-items__active .order__toggle svg {
  transform: rotate(180deg);
  stroke: var(--violet);
}

.campaigns__table .line-items .line-items__title {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: var(--dark-blue);
  opacity: 0.6;
  margin-bottom: 20px;
}

.campaigns__table .line-items .line-items__item {
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  margin-bottom: 15px;
}

.campaigns__table .line-items .line-items__item:hover {
  color: var(--blue);
}

.campaigns__table .table__head {
  cursor: pointer;
}

.campaigns__table .table__head::before {
  content: '';
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 6.8 10.2' style='enable-background:new 0 0 6.8 10.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23b1a1a8;%7D %3C/style%3E%3Cpath class='st0' d='M6.6,3L3.8,0.2c0,0,0,0,0,0C3.6-0.1,3.2-0.1,3,0.2L0.2,3c-0.2,0.2-0.2,0.6,0,0.9c0.2,0.2,0.6,0.2,0.9,0l2.3-2.3 l2.3,2.3C5.9,4,6,4,6.2,4C6.3,4,6.5,4,6.6,3.8C6.9,3.6,6.9,3.2,6.6,3z'/%3E%3Cpath class='st0' d='M6.6,7.3l-2.8,2.8c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0-0.7-0.1L0.2,7.3C0.1,7.2,0,7,0,6.8 c0-0.3,0.3-0.6,0.6-0.6c0.2,0,0.3,0.1,0.5,0.2l2.3,2.3l2.3-2.3c0.2-0.2,0.6-0.2,0.9,0C6.9,6.7,6.9,7,6.6,7.3z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 9px;
  margin-right: 5px;
}

/* -----------------------------
====== ORDER DETAIL PAGE ======
-------------------------------- */

.order-detail {
  padding-bottom: 100px;
}

.order-detail .order-detail__btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.order-detail .order-detail__btns>div:last-child {
  display: flex;
  align-items: center;
}

.order-detail .order-detail__btns>div:last-child .cta:last-child {
  margin-left: 10px;
}

/* ORDER DETAIL BUTTONS BAR STICKY */
.order-detail .order-detail__btns.order-detail__btns--sticky {
  background: transparent linear-gradient(293deg, var(--violet) 0%, var(--blue) 100%) 0% 0%;
  box-shadow: 0px 20px 30px #6a163b26;
  border-radius: 54px;
  padding: 8px;
  position: sticky;
  top: 10px;
  left: 0;
  width: 100%;
  z-index: 9998;
  transform: translate(0, -100px);
  animation: sticky-bar-animation 0.8s ease;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes sticky-bar-animation {
  0% {
    transform: translate(0, -100px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.order-detail .order-detail__btns.order-detail__btns--sticky .cta {
  background: transparent;
  border: 1px solid var(--white);
}

.order-detail .order-detail__btns.order-detail__btns--sticky .cta.cta--outlined {
  display: none;
}

.order-detail .order-detail__btns.order-detail__btns.order-detail__btns--sticky>div:last-child {
  width: 100%;
  justify-content: flex-end;
}

/* With selection enabled */

.order-detail .order-detail__btns.order-detail__btns--sticky.selection-enabled .selection__row {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  color: var(--white);
}

.order-detail .order-detail__btns.order-detail__btns--sticky.selection-enabled .selection__row .cta.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.order-detail .order-detail__btns.order-detail__btns--sticky.selection-enabled .selection__row .cta {
  display: block;
}

.order-detail .order-detail__btns.order-detail__btns--sticky.selection-enabled .selection__row .cta span {
  color: var(--white);
  margin-right: 30px;
}

.order-detail .order-detail__btns.order-detail__btns--sticky.selection-enabled .selection__row .cta svg {
  margin: 0;
}

.order-detail .order-detail__btns.order-detail__btns--sticky.selection-enabled .selection__row>div:first-child span {
  padding-left: 24px;
  font-size: 18px;
}

.order-detail .order-detail__btns.order-detail__btns--sticky.selection-enabled .selection__row>div:last-child {
  display: flex;
  align-items: center;
}

.order-detail .order-detail__btns.order-detail__btns--sticky.selection-enabled .selection__row>div:last-child>span {
  margin-right: 25px;
  font-size: 24px;
}

/* -------------------- */

.order-detail .details {
  margin-bottom: 60px;
}

.order-detail .details .details__row:first-child {
  border-radius: 26px 26px 0px 0px;
  padding: 30px 24px;
  background-color: var(--white);

}

.order-detail .details .details__status {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: var(--red);
  margin-bottom: 15px;
}

.order-detail .details .details__status.details__status--active {
  color: var(--green);
}

.order-detail .details .details__status::before {
  width: 12px;
  height: 12px;
  margin-right: 12px;
  border-radius: 999px;
  background-color: var(--red);
  content: '';
  display: inline-block;
  vertical-align: middle;
}

.order-detail .details .details__status.details__status--active::before {
  background-color: var(--green);
}

.order-detail .details .details__order-id {
  font-size: 24px;
}

.order-detail .details .details__row:last-child {
  background: #FFFFFF99;
  border-radius: 0 0 26px 26px;
  box-shadow: 0px 20px 30px #6a163b26;
  border: 1px solid #FFFFFF;
  padding: 30px 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 20px;
  grid-gap: 0 20px;
}

.order-detail .details .details__row:last-child table td {
  font-weight: 300;
  padding-bottom: 15px;
}

.order-detail .details .details__row:last-child table th {
  padding-bottom: 15px;
  padding-right: 10px;
  text-align: left;
}

.order-details__screenshots {
  position: relative;
  z-index: 0;
  max-width: 1160px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 24px;
  gap: 58px 30px;
  grid-gap: 58px 30px;
}

.order-detail .order-details__total {
  display: block;
  padding-left: 20px;
  padding-bottom: 20px;
}


/* -------------------------------------
====== SCREENSHOT CARD COMPONENT ======
---------------------------------------- */

.screenshot__card {
  width: 100%;
  max-width: 255px;
  border: 1px solid var(--white);
  border-radius: 26px;
}

.screenshot__card .screenshot__image {
  background-color: #FFFFFFB3;
  border-radius: 26px 26px 0 0;
  box-shadow: 0px 8px 16px #ff009924;
}

.screenshot__card .screenshot__image figure {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 160px;
}

.screenshot__card .screenshot__image figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 26px 26px 0 0;
}

.screenshot__card .screenshot__image .screenshot__zoom {
  cursor: pointer;
  background-color: var(--white);
  box-shadow: 0px 0px 10px #6a163b33;
  border-radius: 999px;
  position: absolute;
  top: 7px;
  left: 7px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
}

.screenshot__card .screenshot__image .screenshot__zoom:hover {
  background-color: var(--violet);
}

.screenshot__card .screenshot__image .screenshot__zoom svg {
  width: 22px;
  height: 22px;
  transition: all 0.4s ease-in-out;
  vertical-align: middle;
}

.screenshot__card .screenshot__image .screenshot__zoom:hover svg {
  stroke: var(--white);
}

.screenshot__card .screenshots__infos {
  background-color: #FFFFFFB3;
  padding: 22px 14px;
  border-radius: 0 0 26px 26px;
}

.screenshot__card .screenshots__infos .screenshot__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--dark-blue);
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--white);
  margin-bottom: 15px;
}

.screenshot__card .screenshots__infos .screenshot__head>span {
  width: 85%;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.screenshot__card .screenshots__infos .screenshot__info {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--white);
}


.screenshot__card .screenshots__infos .screenshot__info.align-flex-start {
  align-items: flex-start;
}

.screenshot__card .screenshots__infos .screenshot__size {
  font-size: 13px;
  font-weight: 300;
  display: block;
  margin-top: 5px;
}

.screenshot__card .screenshots__infos .screenshot__info:last-child {
  padding: 0;
  border: 0;
  margin: 0;
}

.screenshot__card .screenshot__reject {
  color: var(--red);
}

.screenshot__card .screenshots__infos .screenshot__info svg {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
}

.screenshot__card .screenshots__infos .screenshot__discard {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 8px;
  margin: auto;
  font-weight: 500;
  background: #ffffff66;
  box-shadow: 0px 4px 8px #013DEE1A;
  color: #ff632fd8;
  border-radius: 12px;
  padding: 8px 12px;
}

.screenshot__card .screenshots__infos .screenshot__info .screenshot__discard svg {
  width: 17px;
  height: 17px;
  margin-right: 0;
}

/* With selection enabled */

.screenshot__card .screenshot__checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 9px;
  box-shadow: 0px 5px 10px #6a163b1A;
  z-index: 10;
}

.screenshot__card .screenshot__checkbox::after {
  height: 20px;
  border: 4px solid var(--white);
  left: 13px;
  top: 4px;
  border-top: 0;
  border-left: 0;
}

.screenshots.selection-enabled .screenshot__card .screenshot__zoom {
  display: none;
}

/* Screenshot Card selected */

.screenshot__card.screenshot__card--selected .screenshot__image figure::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  background-color: var(--blue);
  opacity: 0.5;
  border-radius: 26px 26px 0 0;
  z-index: 2;
}

.screenshot__card.screenshot__card--selected .screenshots__infos {
  color: var(--white);
  background-color: unset;
  background: transparent linear-gradient(143deg, var(--blue) 0%, var(--violet) 100%) 0% 0% no-repeat padding-box;
}

.screenshot__card.screenshot__card--selected .screenshot__reject {
  color: var(--white);
}

.screenshot__card.screenshot__card--selected .screenshot__head {
  color: var(--white);
}

.screenshot__card.screenshot__card--selected svg {
  stroke: var(--white);
  opacity: 0.5;
}

.screenshot__card.screenshot__card--selected svg.mobile-svg-icon {
  stroke: unset;
  fill: var(--white);
}

/* ----------------------------------
====== SELECTION BAR COMPONENT ======
------------------------------------- */

.selection-bar {
  margin: 80px 0 60px 0;
}

.selection-bar .h1 {
  display: block;
  margin-bottom: 20px;
}

.selection-bar .selection-bar__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selection-bar .selection-bar__col {
  display: flex;
  align-items: center;
}

.selection-bar .selection-bar__col .selection-bar__selected-items {
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 11px;
}

.selection-bar .selection-bar__col:first-child>button:first-child {
  margin-right: 25px;
}

.selection-bar .selection-bar__col:last-child>button:first-child {
  margin-right: 16px;
}